<template>
	<client-only>
		<div class="wrapper">
			<div class="tab-buttons">
				<button v-for="(item, index) in value" :key="item.id" class="tab-buttons__button" @click="chooseTab(index)"
					:class="{active: index === activeTab}">
					{{item.tabs_title}}
				</button>
			</div>
			<div class="tab-content" :key="index" v-for="(item, index) in value" :class="{active: index === activeTab}">
				<div class="elements">
					<div class="element" :class="{'fullwidth': item.tabs_items.length < 7}" v-for="(element, index) in item.tabs_items" :key="index" v-html="element.tabs_items_title " />
				</div>
			</div>
		</div>
	</client-only>
</template>
<script>
import ClientOnly from 'vue-client-only'
export default {
	components: { ClientOnly },
	props: {
		value: Array
	},
	data: () => ({
		activeTab: 0
	}),
	methods: {
		chooseTab (index) {
			this.activeTab = index
		}
	}
}

</script>
<style lang="scss" scoped>

.wrapper {
	margin-top: 2rem;

	@include media-breakpoint-up(md) {
		margin-top: 3.6rem;
	}
}
	.tab-buttons {
		display: grid;
		grid-template-columns: auto;
		gap: 0rem 3rem;

		@include media-breakpoint-up(md) {
			grid-template-columns: repeat(2, 1fr);
		}

		@include media-breakpoint-up(xl) {
			grid-template-columns: repeat(2, 1fr);
		}

		&__button {
			font-family: $primary-font;
			font-weight: bold;
			background-color: transparent;
			border: none;
			cursor: pointer;
			flex-grow: 1;
			font-size: 1.8rem;
			padding: 2.4rem 0rem;
			line-height: 2.2rem;
			letter-spacing: 0.08em;
			text-transform: uppercase;
			border-bottom: .3rem solid #eaeaea;
			@include transition;
			color: $black;

			@include media-breakpoint-up(sm) {
				padding: 2.4rem 2rem;
			}

			&.active {
				border-color: $secondary;
				color: $secondary;
			}

			&:hover {
				@include media-breakpoint-up(xl) {
					border-color: $secondary;
					color: $secondary;
				}
			}
		}
	}

	.tab-content {
		margin-top: 4rem;
		display: none;

		&.active {
			display: block;
		}

		.elements {
			display: grid;
			grid-template-columns: auto;
			gap: 1rem;

			@include media-breakpoint-up(md) {
				grid-template-columns: repeat(2, 1fr);
			}

			@include media-breakpoint-up(xxl) {
				grid-template-columns: repeat(2, 1fr);
			}

			::v-deep .element {
				background-color: $white;
				display: flex;
				justify-content: center;
				align-items: center;
				padding: 2rem;
				font-size: 1.6rem;
				letter-spacing: 0.04em;
				text-align: center;
				font-weight: normal;
				color: #BDBDBD;
				line-height: 2.3rem;
				text-transform: uppercase;
				grid-column: 1/3;

				@include media-breakpoint-up(md) {
					font-size: 1.9rem;
				}

				@include media-breakpoint-up(lg) {
					grid-column: initial;
				}
				@include media-breakpoint-up(xl) {
					grid-column: 1/3;
				}

				@include media-breakpoint-up(xxl) {
					grid-column: initial;
				}

				strong {
					color: $primary;
					font-weight: bold;
				}

				&:nth-of-type(1) {
					grid-column: 1/3;
				}
				&:nth-of-type(2) {
					grid-column: 1/3;
				}
				&:last-of-type {
					grid-column: 1/3;
				}

				&.fullwidth:last-of-type {
					@include media-breakpoint-up(xxl){
						grid-column: initial;
					}
				}
			}
		}
	}

</style>
