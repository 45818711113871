<template>
<client-only>
	<section class="section-wrapper" v-animate="'opacity'">
        <base-container>
			<div class="inner-wrapper">
				<div class="section-content">
					<base-heading tag="h2" :heading="`${prefix}.section_title`" :subheading="`${prefix}.section_subtitle`"/>
					<cms-text
						:value="`${prefix}.section_desc`"
						:props="{variant: 'p', color: 'black'}"
						class="description"
					/>
					<base-tab :value="value.tabs" v-if="value.tabs.length"/>
				</div>
				<figure class="img-wrapper">
					<cms-image
						class="img"
						:src="value.section_image"
						position="top"
						:base="{width: 405, height: 180, position: 'center'}"
                        :sm="{width: 305, height: 280, position: 'center'}"
                        :md="{width: 405, height: 180, position: 'center'}"
                        :lg="{width: 705, height: 1042}"
                        :xl="{width: 585, height: 962}"
						layout
					/>
				</figure>
			</div>
		</base-container>
	</section>
</client-only>
</template>

<script>
import BaseTab from '../../components/molecules/BaseTab/BaseTab.vue'
import BaseHeading from '~/website/front/components/molecules/headings/BaseHeading/BaseHeading.vue'
import ClientOnly from 'vue-client-only'
export default {
	components: {
		BaseHeading,
		BaseTab,
		ClientOnly
	},
	props: {
		prefix: String,
		value: Object
	}
}
</script>
<style lang="scss" scoped>

.section-wrapper {
	position: relative;
	padding: 4rem 0;
    @include media-breakpoint-up(xl) {
        padding: 8rem 0;
		overflow: hidden;
    }
    @include media-breakpoint-up(xxl) {
        padding: 0;
    }
	&::after {
		content: "";
		position: absolute;
		top: 50%;
		left: 0;
		transform: translateY(-50%);
		width: 100%;
		height: 100%;
		background-color: $gray-900;
		z-index: -1;

		@include media-breakpoint-up(xl) {
			height: 96%;
		}
	}

	::v-deep(.heading-wrapper){
		@include media-breakpoint-up(xl) {
			margin-bottom: 60px;
		}
	}
}
.img-wrapper {
	box-shadow: $secondary-shadow;
	width: 100vw;
	position: relative;
	left: 50%;
	transform: translateX(-100%);
	margin: 0 0 4rem 50vw;
	display: none;
	@include media-breakpoint-up(lg) {
		width: 515px;
		position: relative;
		right: 0;
		margin: 0;
		transform: translateX(-26%);
		margin-bottom: 0;
	}
	@include media-breakpoint-up(xl) {
		width: auto;
		transform: none;
		margin: 0;
		left: 15%;
		display: block;
	}
	@include media-breakpoint-up(xxl) {
		left: 0;
	}
	@media (min-width: 2500px) {
		width: 400px;
		right: -585px;
	}
	.img {
		@include media-breakpoint-up(xl) {
			display: block;
		}
		}
		@include media-breakpoint-up(xl) {
			display: inline-block;
		}
		::v-deep img {
			width: 100%;
			height: 180px;
			object-fit: cover;
			display: block;
			border-radius: .2rem 0px 0px .2rem;
			@include media-breakpoint-up(md) {
				height: 300px;
			}
			@include media-breakpoint-up(lg) {
				width: auto;
				height: auto;
			}
			@media (min-width: 2500px) {
				width: 1050px;
				height: 1150px;
			}
		}
        ::v-deep picture {
            display: block;
        }
}

.inner-wrapper {
		@include media-breakpoint-up(xl) {
			display: grid;
			padding-right: 75px;
			grid-template-columns: 715px 425px;
			gap: 40px;
			margin-bottom: -10px;
		}
		@include media-breakpoint-up(xxl) {
			gap: 145px;
			grid-template-columns: auto 240px;
		}
	}

.slide-title-wrapper {
		position: relative;
		margin-bottom: 3rem;
		padding-bottom: 2rem;
		margin-left: 1rem;
        margin-top: 1rem;
		display: flex;
		flex-direction: column;
		margin: 1rem auto 2rem auto;

		@include media-breakpoint-up(xl) {
			align-items: center;
			justify-content: center;
		}
        @include media-breakpoint-landscape {
            margin-top: 3rem;
            margin-bottom: 1rem;
        }
		@include media-breakpoint-up(xl) {
            margin-top: 0;
			padding-bottom: 4rem;
			margin-bottom: 3rem;
			margin-left: 3rem;
		}
		@include media-breakpoint-up(xxl) {
			padding-bottom: 5rem;
			margin-bottom: 5rem;
			margin-left: 4rem;
		}

		.slide-title {
			@include media-breakpoint-up(xl) {
				transform: translateX(-10px);
			}
		}
		.slide-subtitle {

		}
		.svg-icon {
			g path {
				fill: black;
			}
			position: absolute;
			top: -2rem;
			left: -1rem;
			max-width: 200px;
			@include media-breakpoint-up(xl) {
				top: -4rem;
				left: -3rem;
				max-width: 425px;
			}
			@include media-breakpoint-up(xxl) {
				top: -5rem;
				left: 8rem;
				max-width: 550px;
			}
		}
	}

	.section-content {
		display: flex;
		flex-direction: column;
		justify-content: center;

		::v-deep(.tab-content) {
			@include media-breakpoint-up(xxl) {
				.element:nth-child(7){
					width: 125%;
				}
				.element:nth-child(8){
					width: 75%;
					justify-self: flex-end;
				}
			}
		}
	}

	.section-description {
		@include media-breakpoint-up(xl) {
			padding-right: 6.8rem;
		}
		.slide-desc {
			text-align: center;
		}
	}

	::v-deep .description {
		text-align: center;

		strong {
			color: $primary;
		}
	}
</style>
